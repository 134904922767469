import * as CONSTANTS from "./cookieconsent.common.js";

// Define dataLayer and the gtag function.
window.dataLayer = window.dataLayer || [];
function gtag() {
    dataLayer.push(arguments);
}

// Set default consent to 'denied' (this should happen before changing any other dataLayer)
gtag("consent", "default", {
    [CONSTANTS.SERVICE_AD_STORAGE]: "denied",
    [CONSTANTS.SERVICE_AD_USER_DATA]: "denied",
    [CONSTANTS.SERVICE_AD_PERSONALIZATION]: "denied",
    [CONSTANTS.SERVICE_ANALYTICS_STORAGE]: "denied",
    [CONSTANTS.SERVICE_FUNCTIONALITY_STORAGE]: "denied",
    [CONSTANTS.SERVICE_PERSONALIZATION_STORAGE]: "denied",
    [CONSTANTS.SERVICE_SECURITY_STORAGE]: "denied",
});
